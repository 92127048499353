.m_token {
  width: 100vw;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_list {
    margin: 20px 0;
    width: 100%;
  }
  &_item {
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &_icon {
      width: 32px;
      height: 32px;
      border-radius: 32px;
    }
    &_name {
      font-weight: 600;
      font-size: 16px;
      color: #212121;
      margin: 0 12px;
      flex: 1;
    }
    &_tag {
      width: 24px;
      height: 24px;
    }
  }
}