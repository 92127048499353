.invition-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  padding-bottom: 100px;
  box-sizing: border-box;

  .image {
    width: 121px;
    height: 96px;
    margin-top: 24px;
  }
  .title {
    margin-top: 24px;
    font-size: 24px;
    color: #212121;
    font-weight: 600;
  }
  .content {
    margin-top: 12px;
    font-size: 14px;
    color: #212121;
    text-align: center;
  }
  .data-list {
    width: 100%;
    margin-top: 28px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .data-item {
      width: calc(50% - 4px);
      border: 1px solid #E6E6E6;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px 0;
      margin: 4px 0;
      box-sizing: border-box;

      .key {
        font-size: 12px;
        color: #808080;
        margin-top: 6px;
      }
      .value {
        font-size: 18px;
        font-weight: 600;
        color: #1437FA;
      }
    }
  }
  .copy-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    padding: 12px 16px;
    box-sizing: border-box;

    .key {
      font-size: 12px;
      color: #808080;
      width: 50px;
    }
    .value {
      margin: 0 8px;
      width: calc(100% - 90px);
      font-size: 12px;
      color: #212121;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  .share-button {
    margin-top: 16px;
    width: 100%;
    height: 48px;
    border-radius: 48px;
    background-color: #1437FA;
    font-size: 16px;
    color: white;
    font-weight: 600;
  }
  .poster-div {
    position: fixed;
    top: 0;
    left: 100vw;
    z-index: -1;

    .poster {
      width: 375px;
      position: relative;

      .bg-image {
        width: 100%;
      }
      .url-div {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 22px;
        bottom: 26px;
        width: 220px;

        .url {
          font-size: 12px;
          line-height: 1.5;
          color: rgba(white, 0.7);
          word-break: break-all;
        }
      }
      .qr-code-div {
        width: 96px;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 24px;
        bottom: 28px;
        background-color: white;
      }
    }
  }
}
.commission-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;

  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    img {
      width: 24px;
      height: 24px;
    }
    .title {
      font-size: 18px;
      color: #212121;
      font-weight: 600;
      width: 100%;
      text-align: center;
      line-height: 44px;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
  .data-list {
    width: 100%;
    border-radius: 16px;
    background-color: #F7F7F7;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    .data-item {
      flex: 1;
      margin: 0 2px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .key {
        font-size: 12px;
        color: #808080;
        margin-top: 6px;
      }
      .value {
        font-size: 18px;
        color: #212121;
        font-weight: 600;
      }
    }
  }
  .record-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    .record-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 0;
      box-sizing: border-box;

      img {
        width: 48px;
        height: 48px;
      }
      .item-content {
        flex: 1;
        margin-left: 12px;
        display: flex;
        flex-direction: column;

        .except-time-div {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .email {
            font-size: 16px;
            color: #212121;
            font-weight: 600;
          }
          .value {
            font-size: 16px;
            color: #10C29F;
          }
        }
        .time {
          margin-top: 5px;
          font-size: 12px;
          color: #808080;
        }
      }
    }
  }
}
.people-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;

  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    img {
      width: 24px;
      height: 24px;
    }
    .title {
      font-size: 18px;
      color: #212121;
      font-weight: 600;
      width: 100%;
      text-align: center;
      line-height: 44px;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
  .data-list {
    width: 100%;
    border-radius: 16px;
    background-color: #F7F7F7;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    .data-item {
      flex: 1;
      margin: 0 2px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .key {
        font-size: 12px;
        color: #808080;
        margin-top: 6px;
      }
      .value {
        font-size: 18px;
        color: #212121;
        font-weight: 600;
      }
    }
  }
  .record-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    .record-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 12px 0;
      box-sizing: border-box;
     
      .except-time-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .email {
          font-size: 16px;
          color: #212121;
          font-weight: 600;
        }
        .value {
          font-size: 16px;
        }
      }
      .time {
        margin-top: 5px;
        font-size: 12px;
        color: #808080;
      }
    }
  }
}
.share-popup {
  width: 100vw;
  height: 90vh;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .share-image {
    width: 250px;
    margin-top: 50px;
  }
  .save-text {
    font-size: 12px;
    color: #808080;
    margin: 20px 0;
  }
}