.m_applyway {
  width: 100vw;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &_close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  &_title {
    font-size: 16px;
    color: #212121;
    font-weight: 600;
    text-align: center;
    margin: 20px 0;
  }
  &_item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px;
    margin: 10px 0;
    background-color: var(--bgcolor, white);
    border: 1px solid var(--bdcolor, transparent);

    &:last-child {
      margin-bottom: 50px;
    }
    img {
      width: 32px;
      height: 32px;

      &:last-child {
        width: 24px;
        height: 24px;
      }
    }
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: 6px;
      margin: 0 10px;

      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 6px;
        font-size: var(--fonts, 14px);
        font-weight: var(--fonts, 400);
        color: var(--color, #212121);

        span {
          font-size: 12px;
          color: white;
          padding: 3px 6px;
          background-color: #FAA805;
          border-radius: 4px;
          display: inline-block;
        }
      }
    }
  }
}