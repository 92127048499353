.p_cardcost {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  box-sizing: border-box;
  

  &_navbar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
  &_name {
    color: #212121;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 32px;
  }
}