.m_tabbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  box-sizing: border-box;
  filter: drop-shadow(0px -0.5px 0px rgba(0, 0, 0, 0.1));
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;

  &_item {
    display: flex;
    flex: 1;
    height: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
    p {
      font-size: 10px;
      margin-top: 3px;
    }
  }
}