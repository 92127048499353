.p_cardapply {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;

  &_navbar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
  &_title {
    font-size: 32px;
    font-weight: 600;
    color: #212121;
    margin-top: 8px;
    width: 100%;
  }
  &_session {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    p {
      font-size: 14px;
      color: #212121;
    }
  }
  &_input {
    background-color: #F7F7F7;
    border-radius: 8px;
    margin-top: 6px;
    padding: 14px 16px;
    box-sizing: border-box;
    
    .adm-input-element {
      font-size: 16px;
      color: #212121;
    }
  }
  &_confirm {
    width: calc(100% - 32px);
    height: 48px;
    border-radius: 48px;
    background-color: #1437FA;
    font-size: 16px;
    font-weight: 600;
    color: white;
    position: absolute;
    bottom: 20px;
    left: 16px;
  }
}