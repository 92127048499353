.m_recharge {
  width: 100vw;
  height: 90vh;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  &_title {
    color: #212121;
    font-size: 18px;
    margin-top: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &_content {
    color: #808080;
    font-size: 14px;
    margin-top: 20px;
    font-weight: 600;
  }
  &_menus {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 12px;
  }
  &_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F7F8F9;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;

    &_t1 {
      font-size: 18px;
      color: #212121;
      font-weight: 600;
      margin-top: 15px;
    }
    &_t2 {
      font-size: 14px;
      color: #808080;
      margin-top: 5px;
      margin-bottom: 20px;
    }
    &_placehorder {
      font-size: 14px;
      color: #808080;
    }
    &_amount {
      font-size: 25px;
      color: #1437FA;

      span {
        display: inline-block;
        width: 2px;
        height: 20px;
        margin-left: 5px;
        background-color: #1437FA;
        -webkit-animation: blink 0.5s infinite;
        animation: blink 0.5s infinite;
      }
      @keyframes blink {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
  &_pay {
    margin-top: 30px;
    width: 100%;
    border: 1px solid #E6E6E6;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 16px;
    box-sizing: border-box;
    column-gap: 12px;

    img {
      width: 32px;
      height: 32px;
    }
    &_data {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      flex: var(--flex, 0);

      p {
        font-size: var(--fonts, 14px);
        color: var(--color, #808080);
        font-weight: var(--fontw, 400);
      }
    }
  }
  &_confirm {
    width: 100%;
    height: 48px;
    border-radius: 48px;
    background-color: #1437FA;
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin-top: 20px;
  }
  &_question {
    color: #1437FA;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
  }
  &_txt {
    color: #808080;
    font-size: 14px;
    margin-top: 20px;
  }
}
.adm-number-keyboard-key.adm-number-keyboard-key-ok {
  background-color: #1437FA !important;
}