.m_contact {
  padding: 0;
  overflow-y: hidden;
  
  .adm-modal-content {
    padding: 0;
    overflow-y: hidden;
  }
  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_title {
    font-size: 16px;
    color: #212121;
    font-weight: 600;
    text-align: center;
    margin: 20px 0;
  }
  &_content {
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
    color: #808080;
    text-align: center;
  }
  &_buttons {
    width: 100%;
    height: 48px;
    margin-top: 25px;
    border-top: 1px solid #F7F7F7;
    display: flex;
    flex-direction: row;
    position: relative;


    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      width: 1px;
      height: 100%;
      transform: translateX(-50%);
      background-color: #F7F7F7;
    }
  }
  &_button {
    flex: 1;
    height: 100%;
    background-color: transparent;
    border-style: none;
    font-size: 16px;
    border-radius: 0;
    color: var(---color, #212121);
  }
}
.adm-modal-footer.adm-space {
  display: none;
}
.adm-modal {
  z-index: 999999;
}