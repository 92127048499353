.swap-page {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    img {
      width: 24px;
      height: 24px;
    }
    .title {
      font-size: 18px;
      color: #212121;
      font-weight: 600;
      width: 100%;
      text-align: center;
      line-height: 44px;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
  .body-div {
    margin-top: 16px;
    width: 100%;
    padding: 22px 20px;
    box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.08);
    border-radius: 16px 16px 16px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .session-div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .session-left {
        display: flex;
        flex-direction: column;

        .info-div {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
          }
          p {
            font-size: 18px;
            color: #212121;
            margin-left: 10px;
            font-weight: 600;
          }
        }
        .balance {
          margin-top: 15px;
          font-size: 15px;
          color: #808080;
          white-space: nowrap;
        }
      }
      .session-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .input {
          flex: 1;
          height: 34px;
          margin-top: -10px;
  
          .adm-input-element {
            font-size: 28px;
            color: #212121;
            font-weight: 600;
            --text-align: right;
          }
        }
        .max-button {
          margin-top: 8px;
          font-size: 15px;
          color: #1437FA;
        }
        .outputAmount {
          font-size: 28px;
          color: #212121;
          font-weight: 600;
        }
      }
    }
    .swap-icon {
      width: 36px;
      height: 36px;
      margin: 0 auto;
      margin-top: 8px;
      margin-bottom: 22px;
    }
    .swapButton {
      border-radius: 48px;
      margin-top: 32px;
      width: 100%;
      height: 48px;
      font-size: 16px;
      font-weight: 600;
      color: white;
      background-color: #1437FA;
    }
  }
}
.swap-popup {
  width: 100vw;
  height: 90vh;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .title {
    color: #212121;
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .session-div {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .key {
      font-size: 14px;
      color: #808080;
    }
    .value1 {
      font-size: 24px;
      color: #212121;
      font-weight: 600;
    }
    .value2 {
      font-size: 24px;
      color: #1437FA;
      font-weight: 600;
    }
  }
  .confirm-button {
    width: calc(100% - 32px);
    height: 48px;
    border-radius: 48px;
    background-color: #1437FA;
    font-size: 16px;
    font-weight: 600;
    color: white;
    position: absolute;
    bottom: 20px;
    left: 16px;
  }
}