.recharge-page {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #1437FA 0%, #147CFA 100%), #D9D9D9;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    img {
      width: 24px;
      height: 24px;
    }
    .title {
      font-size: 18px;
      color: white;
      font-weight: 600;
      width: 100%;
      text-align: center;
      line-height: 44px;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
  .body-div {
    margin: 8px 0;
    width: 100%;
    border-radius: 16px;
    padding: 24px 16px;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    .network {
      color: #808080;
      font-size: 12px;
    }
    .network-list {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .network-item {
        font-size: 12px;
        color: #808080;
        border: 1px solid #E6E6E6;
        border-radius: 16px;
        margin: 0 4px;
        padding: 8px 12px;
      }
      .network-item-sel {
        border-color: #1437FA;
        background-color: rgba(#1437FA, 0.1);
        color: #1437FA;
      }
    }
    .qr-code-div {
      margin-top: 12px;
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .address {
      width: 200px;
      word-break: break-all;
      margin-top: 12px;
      box-sizing: border-box;
      text-align: center;
      font-size: 14px;
      color: #808080;
    }
    .minimum {
      width: 100%;
      margin-top: 24px;
      border-radius: 8px;
      background-color: #F7F7F7;
      padding: 17px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 12px;
      }
    }
    .copy-button {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: white;
      font-weight: 600;
      background-color: #1437FA;
      border-radius: 48px;
      margin-top: 32px;
      margin-bottom: 8px;
    }
  }
  .recharge-logo {
    height: 16px;
    margin-top: 32px;
  }
  .customer-button {
    width: 124px;
    height: 32px;
    border: 1px solid white;
    border-radius: 32px;
    margin: 24px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
    }
    p {
      font-weight: 600;
      font-size: 14px;
      color: white;
      margin-left: 4px;
    }
  }
}
.recharge-modal {
  padding: 0;
  overflow-y: hidden;

  .adm-modal-content {
    padding: 0;
    overflow-y: hidden;
  }
  .modal-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 16px;
      color: #212121;
      font-weight: 600;
      text-align: center;
      margin: 20px 0;
    }
    .content {
      width: 100%;
      font-size: 14px;
      line-height: 1.5;
      color: #808080;
      margin: 0 32px;
      text-align: center;

      span {
        display: inline-block;
        margin-top: 10px;
      }
    }
    .buttons-div {
      width: 100%;
      height: 48px;
      margin-top: 25px;
      border-top: 1px solid #F7F7F7;
      display: flex;
      flex-direction: row;

      .button {
        flex: 1;
        height: 100%;
        background-color: transparent;
        border-style: none;
        font-size: 16px;
        border-radius: 0;
      }
      .cancle {
        color: #212121;
      }
      .confirm {
        color: #1437FA;
        border-left: 1px solid #F7F7F7;
      }
    }
  }
}
.adm-modal-footer.adm-space {
  display: none;
}