.mine-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  padding-bottom: 100px;
  box-sizing: border-box;

  .title {
    width: 100%;
    color: #212121;
    font-size: 24px;
    font-weight: 600;
    margin: 24px 0;
  }
  .sessin-div {
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    .key {
      font-size: 16px;
      color: #212121;
      flex: 1;
    }
    .value {
      font-size: 16px;
      color: #808080;
    }
    .tag {
      width: 24px;
      height: 24px;
    }
  }
  .logout-button {
    width: 100%;
    margin-top: 24px;
    border: 1px solid #212121;
    border-radius: 25px;
    font-size: 16px;
    color: #212121;
    font-weight: 600;
  }
}
.mine-modal {
  padding: 0;
  overflow-y: hidden;

  .adm-modal-content {
    padding: 0;
    overflow-y: hidden;
  }
  .modal-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 16px;
      color: #212121;
      font-weight: 600;
      text-align: center;
      margin: 20px 0;
    }
    .content {
      width: 100%;
      font-size: 14px;
      line-height: 1.5;
      color: #808080;
      margin: 0 32px;
      text-align: center;
    }
    .buttons-div {
      width: 100%;
      height: 48px;
      margin-top: 25px;
      border-top: 1px solid #F7F7F7;
      display: flex;
      flex-direction: row;

      .button {
        flex: 1;
        height: 100%;
        background-color: transparent;
        border-style: none;
        font-size: 16px;
        border-radius: 0;
      }
      .cancle {
        color: #212121;
      }
      .confirm {
        color: #1437FA;
        border-left: 1px solid #F7F7F7;
      }
    }
  }
}
.adm-modal-footer.adm-space {
  display: none;
}