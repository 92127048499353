.p_cardinfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-bottom: 20px;
  box-sizing: border-box;

  &_navbar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
  &_title {
    font-size: 32px;
    font-weight: 600;
    color: #212121;
    margin: 8px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &_copy {
    font-size: 14px;
    color: #1437FA;
    font-weight: 600;
  }
  &_session {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    &_key {
      font-size: 18px;
      color: #212121;
      font-weight: 600;
    }
    &_row {
      margin-top: 8px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 16px;
        color: #212121;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}