.m_cost {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &_item {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 28px;

    img {
      width: 48px;
      height: 48px;
      margin-right: 11px;
    }
  }
  &_number {
    color: #212121;
    font-size: 18px;
    font-weight: 600;
  }
  &_text {
    color: #808080;
    font-size: 14px;
    margin-top: 3px;
  }
}