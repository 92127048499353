.verify-account-page{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;

  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;

    .back-button {
      width: 24px;
      height: 24px;
      padding: 10px;
      margin-left: 14px;
      background-color: white;
    }
  }
  .title {
    width: calc(100% - 48px);
    font-size: 32px;
    font-weight: 600;
    color: #212121;
    text-align: left;
    margin: 8px 24px;
  }
  .content {
    width: calc(100% - 48px);
    font-size: 14px;
    line-height: 21px;
    color: #808080;
    text-align: left;
    margin: 8px 24px;
  }
  .input-name {
    width: calc(100% - 48px);
    font-size: 14px;
    color: #212121;
    text-align: left;
    margin: 24px;
    margin-bottom: 16px;
  }
  .input-container {
    margin: 0 24px 0 24px;
    width: calc(100% - 48px);
    height: 50px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;

    .input-div {
      flex: 1;
      --font-size: 16px;
      --color: #212121;
      
      .adm-input-element {
        line-height: 16px;
      }
    }
    .resend-button {
      font-size: 16px;
      color: #1437FA;
      margin-left: 10px;
    }
    .resend-button-disable {
      color: rgba(#1437FA, 0.5);
    }
  }
  .input-container-active {
    border-color: #1437FA;
  }
  .send-button {
    width: calc(100% - 188px);
    height: 50px;
    border-radius: 25px;
    background-color: #1437FA;
    box-shadow: none;
    font-size: 16px;
    color: white;
    margin: 40px 0;

    &::before {
      border-radius: 25px;
    }
  }
  .send-button-disable {
    background-color: rgba(#1437FA, 0.5);
  }
  .forget-button {
    color: #1437FA;
    font-weight: 600;
    font-size: 16px;
    margin: 32px 0;
  }
}