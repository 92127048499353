.m_customer {
  width: 100vw;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  &_title {
    color: #212121;
    font-size: 18px;
    margin-top: 16px;
    font-weight: 600;
  }
  &_content {
    color: #808080;
    font-size: 14px;
    margin-top: 20px;
    font-weight: 600;
  }
  &_code {
    width: 250px;
    height: 250px;
    margin-top: 20px;
  }
  &_t {
    color: #CCCCCC;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}