.token-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 50px;

  .content-div {
    width: 100%;
    background: linear-gradient(180deg, #1437FA 0%, #147CFA 100%), #D9D9D9;
    padding: 16px;
    box-sizing: border-box;

    .nav-bar {
      height: 44px;
      display: flex;
      flex-direction: row;
      align-items: center;
  
      img {
        width: 24px;
        height: 24px;
      }
    }
    .number {
      font-size: 36px;
      font-weight: 600;
      color: white;
      margin-top: 8px;

      .type {
        font-size: 24px;
        font-weight: 400;
        margin: 0;
      }
    }
    .value {
      margin-top: 6px;
      font-size: 16px;
      color: #9EADFF;
    }
    .menu-list {
      margin-top: 24px;
      margin-bottom: 40px;
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: row;
      background-color: rgba(20, 55, 250, 0.5);
      border-radius: 16px;

      .menu-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
          height: 24px;
        }
        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }
  }
  .record-list {
    width: 100vw;
    margin-top: -20px;
    margin-bottom: 20px;
    padding: 0 16px;
    padding-top: 20px;
    border-radius: 16px 16px 0 0;
    background-color: white;
    box-sizing: border-box;

    .record-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 32px;
      }
      .record-desc-div {
        margin-left: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
        padding: 18px 0;
        border-bottom: 1px solid rgba(black, 0.1);

        .content {
          display: flex;
          flex-direction: column;

          .t1 {
            font-size: 16px;
            color: #212121;
          }
          .t2 {
            font-size: 12px;
            color: #808080;
            margin-top: 8px;
            word-break: break-all;
          }
        }
      }
    }
    .empty-text {
      font-size: 16px;
      color: #808080;
      margin-top: 100px;
      width: 100%;
      text-align: center;
    }
  }
}
.token-popup {
  width: 100vw;
  height: 90vh;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .title {
    color: #212121;
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .session-div {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    .name {
      font-size: 14px;
      color: #212121;
    }
    .input-div {
      margin-top: 6px;
      width: 100%;
      background-color: #F7F7F7;
      border-radius: 8px;
      padding: 14px 16px;
      box-sizing: border-box;

      .input-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .input {
          flex: 1;
          
          .adm-input-element {
            font-size: 16px;
            color: #212121;
            --placeholder-color: #808080;
          }
        }
        .all-button {
          font-size: 16px;
          color: #1437FA;
          margin-left: 16px;
        }
        .resend-button {
          font-size: 16px;
          color: #1437FA;
          margin-left: 16px;
        }
        .resend-button-disable {
          color: rgba(#1437FA, 0.5);
        }
      }
      .value {
        margin-top: 16px;
        font-size: 12px;
        color: #808080;
      }
    }
    .network-list {
      margin-top: 12px;
      display: flex;
      flex-direction: row;

      .network-item {
        font-size: 12px;
        color: #808080;
        border: 1px solid #E6E6E6;
        border-radius: 16px;
        margin: 0 4px;
        padding: 8px 12px;
      }
      .network-item-sel {
        border-color: #1437FA;
        background-color: rgba(#1437FA, 0.1);
        color: #1437FA;
      }
    }
    .type-div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .key {
        font-size: 14px;
        color: #808080;
      }
      .value1 {
        font-size: 24px;
        color: #212121;
        font-weight: 600;
      }
      .value2 {
        font-size: 24px;
        color: #1437FA;
        font-weight: 600;
      }
      .value3 {
        font-size: 18px;
        color: #212121;
        font-weight: 600;
      }
    }
    .dollar {
      width: 100%;
      text-align: right;
      font-size: 14px;
      color: #808080;
      margin-top: 8px;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
    margin: 32px 0;
  }
  .receive-address {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #212121;
    word-break: break-all;
    text-align: right;

    span {
      display: inline-block;
      color: #808080;
      margin-right: 100px;
    }
  }
  .txt {
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
    color: #808080;

    span {
      display: inline-block;
      width: 70px;
    }
  }
  .confirm-button {
    width: calc(100% - 32px);
    height: 48px;
    border-radius: 48px;
    background-color: #1437FA;
    font-size: 16px;
    font-weight: 600;
    color: white;
    position: absolute;
    bottom: 20px;
    left: 16px;
  }
}